/** @format */

.App {
	align-items: flex-start;
	background-color: rgb(0, 0, 0);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	max-width: min(80%, 700px);
	margin-left: auto;
	margin-right: auto;
}

.top {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.bottom {
	align-items: center;
	display: flex;
  height: 60px;
	flex-direction: row;
	justify-content: flex-start;
  width: 100%;
}

.App .title {
	font-size: 64px;
	line-height: 60px;
	margin-bottom: 20px;
	text-align: left;
}

.App .subtitle {
  color: lightblue;
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 40px;
	text-align: left;
}

.App .text {
	color: rgb(160, 160, 160);
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
	margin-bottom: 20px;
	text-align: left;
}

.App .sign-off {
	font-size: 18px;
	color: rgb(160, 160, 160);
	font-weight: 400;
	margin-bottom: 0;
	text-align: left;
}

.App .signature {
	font-size: 18px;
	color: rgb(160, 160, 160);
	font-weight: 400;
	margin-top: 6px;
	text-align: left;
}

.App .contact {
  margin-left: 4px;
}

.App .contact,
.App .contact:visited,
.App .contact:focus,
.App .contact:active {
	color: white;
  cursor: pointer;
  text-decoration: none;
}

.App .contact:hover {
	color:  lightblue;
  cursor: pointer;
  text-decoration: none;
}
